import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';

import mq from '@/styles/mq';

interface Props {
  faqs: React.ReactNode;
}

const FaqsContentRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(10)};
  padding-bottom: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(20)};
    padding-left: ${({ theme }) => theme.spacing(0)};
    padding-right: ${({ theme }) => theme.spacing(0)};
  }
`;

const FaqsContent = ({ faqs }: Props): JSX.Element => {
  return <FaqsContentRoot maxWidth="lg">{faqs}</FaqsContentRoot>;
};

export const query = graphql`
  fragment Faqs on PrismicLegalPagesDataType {
    faqs_page {
      raw
    }
  }
`;

export default FaqsContent;
