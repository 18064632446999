import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import FaqsContainer from '@/containers/Faqs/FaqsContainer';

import { LegalPagesQuery } from '../../../graphql-types';

interface Props {
  data: LegalPagesQuery;
}

export const query = graphql`
  query FaqsLegalPages {
    prismicLegalPages {
      data {
        meta_title_faqs
        meta_description_faqs
        ...Faqs
      }
    }
  }
`;

const Faqs = ({ data }: Props): JSX.Element => {
  const { meta_title_faqs, meta_description_faqs } =
    data?.prismicLegalPages?.data || {};
  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title_faqs || 'title'}
        description={meta_description_faqs || 'description'}
      />
      <FaqsContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Faqs;
