import React from 'react';
import { RichText } from 'prismic-reactjs';
import FaqsContent from '@/containers/Faqs/FaqsContent';

import useHtmlSerializer from '@/prismic/useHtmlSerializer';

import { LegalTemplateQuery } from '../../../graphql-types';

interface Props {
  data: LegalTemplateQuery;
  type?: string;
}

const FaqsContainer = ({ data }: Props): JSX.Element => {
  const htmlSerializer = useHtmlSerializer();
  const { faqs_page } = data?.prismicLegalPages?.data || {};

  const FaqsProps = {
    faqs: <RichText render={faqs_page?.raw} htmlSerializer={htmlSerializer} />,
  };
  return (
    <>
      <FaqsContent {...FaqsProps} />
    </>
  );
};

export default FaqsContainer;
